table, td, th {
    border: 1px solid #E5E5E5;
    text-align: center;
    padding: 7px 10px;
}
  
table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ddd;
}

th {
    background-color: #1498E6;
}

tr {
    white-space: nowrap;
}

tr:nth-child(odd){background-color: #f2f2f2}
