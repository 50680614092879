body {
  margin: 0;
  /* background-color: #FFF; */
  /* color: #000; */
  font-family: myFont;
}

@font-face {
  font-family: myFont;
  src: url('fonts/VespiarioThai-Regular.otf');
}

@font-face {
  font-family: myFontBold;
  src: url('fonts/VespiarioThai-Bold.otf');
}

.menu {
  height: 100%;
}
.menu__list {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
}
.menu__item {
  height: 100%;
  width: 33.33%;
}
.menu__anchor {
  font-size: 5.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 24.6rem;
  justify-content: center;
  color: #000;
  text-decoration: none;
  height: 100%;
}

.coupon__anchor {
  display: block;
  width: 100%;
}
.coupon__image {
  display: block;
  width: 100%;
}
.coupon__anchor--expired {
  filter: opacity(0.3) grayscale(1);
  -webkit-filter: opacity(0.3) grayscale(1);
  pointer-events: none;
}

.App-header {
  background-color: #FFF;
  /* min-height: 100vh; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: 26px; */
  color: #000;
  font-family: myFont;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.container {
  /* visibility: hidden; */
  height: 100vh;
}
.container--empty {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.wrapper {
  margin: auto;
  /* width: 112.5rem; */
  height: 100%;
}
.wrapper--empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  background: #6accb2;
}
/* .brand {
  &__image {
      display: block;
      height: (190rem / 10);
  }
} */
.brand__image {
  display: block;
  /* height: 19rem; */
  width: 100%;
}

.content {
  padding: 0 0 45rem;
  /* padding: 0 0 25rem; */
}
.content--empty {
  padding: 0;
  flex: 1;
}

.heading {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  width: 100%;
  box-sizing: border-box;
  font-size: 30px;
}

.heading__tab {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.heading__anchor--active {
  pointer-events: none;
  opacity: 1;
}
.heading__anchor {
  /* background: url("../images/heading__anchor.png") no-repeat 0 0/cover; */
  cursor: pointer;
  height: 6.8rem;
  position: absolute;
  left: 6.3rem;
  width: 4.8rem;
}

.coupon {
  margin: auto;
}
.coupon__item {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  /* margin: 0 0 5rem; */
  margin: 0 0 1rem;
  justify-content: center;
}
.coupon__item:last-child {
  margin: 0 0 10rem;
}
.coupon__item--hide {
  display: none;
}


.coupon__anchor--used {
  filter: opacity(0.3) grayscale(1);
  -webkit-filter: opacity(0.3) grayscale(1);
  pointer-events: none;
}

.coupon__number-wrap {
  align-items: flex-start;
  justify-content: flex-end;
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 100%;
  pointer-events: none;
}
.coupon__number {
  border: 1px solid;
  background: white;
  color: #000;
  /* font-size: 4.3rem; */
  font-size: 24px;
  padding: 1rem 2rem;
  margin: 1rem;
  border-radius: 10rem;
}

.coupon__age-wrap {
  /* background: #000;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 6vw;
  width: 100%;
  line-height: 1.4;
  text-align: center; */
}
.coupon__age {
  /* color: white; */
  /* font-size: 5.4rem; */
  /* top: 0; */
  /* right: 5vw; */
}
.coupon__age--used {
  /* color: white; */
}

.coupon__runout {
  position: absolute;
  font-size: 6rem;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4rem 0;
  text-align: center;
  width: 100%;
}

.coupon__detail {
  position: absolute;
  /* font-size: 7rem; */
  font-size: 24px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4rem 7rem;
  text-align: center;
  border-radius: 10rem;
  text-decoration: none;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.empty__icon {
  height: 20rem;
  width: 20rem;
  margin: 0 0 2rem;
}
.empty__message {
  /* font-size: 8.6rem; */
  opacity: 0.3;
}

select {
  /* -webkit-appearance: none;
  text-transform: none; */
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */

  /* margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none; */
}

select {

  /* styling */
  /* background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em; */

  /* reset */
  width: 100%;
  border: 1px solid #CCCCCC;
  height: 30px;
  outline: none;
  padding: 0 10px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #FAFAFA;

  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.minimal {
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 12px) calc(1em + 0px),
  calc(100% - 8px) calc(1em + 0px);
  background-size:
    5px 5px,
    5px 5px,
    1px 1px;
  background-repeat: no-repeat;
  font-size: 12px;
}

.header_profile {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  /* height: 65rem; */
  /* height: 12rem; */
  justify-content: center;
  margin: 0 auto 3rem;
  position: relative;
}
.avatar__frame {
  background: #3a4440;
  border-radius: 27rem;
  /* height: 54rem; */
  overflow: hidden;
  /* width: 54rem; */
  height: 150px;
  width: 150px;
}

.language {
  /* font-size: 10rem; */
  padding: 0.5rem;
  position: absolute;
  /* right: 10rem; */
  right: 1rem;
  top: 0;
}
.language__anchor {
  cursor: pointer;
  display: inline-block;
  line-height: 0.8;
  padding: 0.25rem;
}
.language__anchor--active {
  color: white;
}

.form {
  margin: auto;
  /* width: 185rem; */
  /* padding: 0 0 60rem; */
}
.form__list {
  list-style: none;
  padding: 0;
}
.form__item {
  /* margin: 0 0 5rem; */
}
.form__item--model {
  position: relative;
}
.form__item--model:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3em 2rem 0 2rem;
  border-color: #000000 transparent transparent transparent;
  position: absolute;
  right: 10rem;
  top: 8rem;
}
.form__item--model--disable:after {
  display: none;
}
.form__item--error {
  margin: 0 0 5rem;
}
.form__field-label {
  display: block;
  /* text-indent: 3rem; */
  /* line-height: 18rem; */
}
.form__field-label--inline {
  display: inline-block;
  width: 83rem;
}
.form__field-label--radio {
  line-height: 1;
}
.form__field-label--th {
  font-size: 11rem;
}
@media only screen and (min-width: 1024px) {
  .form__field-label--th {
    font-size: 8rem;
  }
}
.form__field-label--en {
  font-size: 12rem;
}
@media only screen and (min-width: 1024px) {
  .form__field-label--en {
    font-size: 8rem;
  }
}
.form__field-input {
  background: #e5e5e5;
  border: none;
  border-radius: 10rem;
  box-sizing: border-box;
  height: 18rem;
  outline: none;
  padding: 0 5rem;
  width: 96rem;
}
.form__field-input--th {
  font-size: 11rem;
}
@media only screen and (min-width: 1024px) {
  .form__field-input--th {
    font-size: 8rem;
  }
}
.form__field-input--en {
  font-size: 12rem;
}
@media only screen and (min-width: 1024px) {
  .form__field-input--en {
    font-size: 8rem;
  }
}
.form__field-input[disabled] {
  background: none;
  color: #000;
}
.form__field-input[disabled]::placeholder {
  color: #000;
}
.form__field-input:focus {
  box-shadow: inset 0 0 0px 2px #69cdb3;
}
.form__field-radio {
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}
.form__field-radio:checked ~ .form__field-check {
  background: url("./images/radio--checked.png") no-repeat 50% 50%/100%;
}
.form__field-select {
  background: #e5e5e5;
  border: none;
  border-radius: 10rem;
  box-sizing: border-box;
  height: 18rem;
  outline: none;
  padding: 0 5rem;
  -webkit-appearance: none;
}
.form__field-select--date {
  display: inline-block;
}
.form__field-select--date[disabled] {
  padding: 0 0 0 3rem;
}
.form__field-select--month {
  display: inline-block;
}
.form__field-select--month[disabled] {
  padding: 0 5rem;
}
.form__field-select--year {
  display: inline-block;
}
.form__field-select--year[disabled] {
  padding: 0;
}
.form__field-select--model {
  padding: 0 0 0 5rem;
  width: 96rem;
}
.form__field-select--model--empty {
  color: rgba(0, 0, 0, 0.3);
}
.form__field-select--th {
  font-size: 11rem;
}
@media only screen and (min-width: 1024px) {
  .form__field-select--th {
    font-size: 8rem;
  }
}
.form__field-select--en {
  font-size: 12rem;
}
@media only screen and (min-width: 1024px) {
  .form__field-select--en {
    font-size: 8rem;
  }
}
.form__field-select[disabled] {
  background: none;
  color: #000;
}
.form__field-select:focus {
  box-shadow: inset 0 0 0px 2px #69cdb3;
}
.form__field-wrap {
  display: inline-block;
  position: relative;
  vertical-align: top;
}
.form__field-wrap--radio {
  margin: 0 10rem 0 0;
}
.form__field-wrap--birthdate {
  margin: 0;
}
.form__field-wrap--birthyear {
  margin: 0;
}
.form__field-check {
  /* background: url("../images/radio.png") no-repeat 50% 50%/100%; */
  bottom: 0;
  height: 8rem;
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
  width: 8rem;
}
.form__field-title {
  display: inline-block;
  font-size: 11em;
  text-indent: 5rem;
}
@media only screen and (min-width: 1024px) {
  .form__field-title {
    font-size: 8rem;
  }
}
.form__field-title--edit {
  text-indent: 10rem;
}
.form__field-error {
  color: red;
  text-indent: 4rem;
}
.form__field-error--th {
  font-size: 9rem;
}
@media only screen and (min-width: 1024px) {
  .form__field-error--th {
    font-size: 7rem;
  }
}
.form__action {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 15rem 0 10rem;
  text-align: center;
}